import React, { CSSProperties, ComponentProps } from 'react';
import { AlertStyled } from './AlertMessage.style';

export type AlertMessageProps = ComponentProps<typeof AlertStyled> & {
  marginTop?: CSSProperties['marginTop'];
  marginBottom?: CSSProperties['marginBottom'];
};

export function AlertMessage({
  message,
  marginTop,
  marginBottom,
  ...props
}: AlertMessageProps): JSX.Element | null {
  const [showAlert, setShowAlert] = React.useState(true);

  let onClose;
  if (props.showCloseButton && !props.onClose) {
    onClose = () => {
      setShowAlert(false);
    };
  }

  return showAlert ? (
    <AlertStyled
      message={message}
      type="info"
      onClose={onClose}
      css={{ $$marginTop: marginTop ?? 0, $$marginBottom: marginBottom ?? 0 }}
      {...props}
    />
  ) : null;
}
