import React, { ComponentProps } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Error404 as HexaError404 } from '@hexa-ui/components';
import useStyles from './404.styles';

interface Error404Props {
  goBackCallback?: ComponentProps<typeof HexaError404>['buttonClickHandler'];
}

export function Error404({ goBackCallback }: Error404Props): JSX.Element {
  const { formatMessage } = useIntl();
  const { notFound } = useStyles();

  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <div data-testid="error-404-page" className={notFound}>
      <HexaError404
        buttonClickHandler={goBackCallback ?? goBack}
        headerText={formatMessage({ id: 'COMPONENTS.NOT_FOUND_HEADER_TEXT' })}
        subHeaderText={formatMessage({ id: 'COMPONENTS.NOT_FOUND_SUBHEADER_TEXT' })}
        buttonText={formatMessage({ id: 'COMPONENTS.NOT_FOUND_HOME_BTN' })}
      />
    </div>
  );
}
