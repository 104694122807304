import React from 'react';
import { useIntl } from 'react-intl';
import { Error500 as HexaError500 } from '@hexa-ui/components';
import useStyles from './500.styles';

export function Error500(): JSX.Element {
  const { formatMessage } = useIntl();
  const { error } = useStyles();

  return (
    <div className={error}>
      <HexaError500 headerText={formatMessage({ id: 'COMPONENTS.ERROR_GENERIC' })} />
    </div>
  );
}
