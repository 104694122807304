import React from 'react';
import { Breadcrumb as HexaBreadcrumb } from '@hexa-ui/components';
import { Container } from './Breadcrumb.style';

export interface BreadcrumbProps {
  items: BreadcrumbItem[];
  isFromCompany?: boolean;
}

interface BreadcrumbItem {
  label: string;
  href: string;
  isCurrentPage?: boolean;
}

const baseHome = '/distribution-centers';
const companyHome = '/company-management';

const { Root, Item, HomeItem } = HexaBreadcrumb;
export function Breadcrumb({ isFromCompany, items }: BreadcrumbProps): JSX.Element | null {
  const home = isFromCompany ? companyHome : baseHome;

  return (
    <Container data-testid="breadcrumb-container">
      <Root>
        <HomeItem data-testid="breadcrumb-home-item" href={home}>
          Home
        </HomeItem>
        {items.map(({ label, href, isCurrentPage }) => (
          <Item data-testid="breadcrumb-item" key={label} href={href} isCurrentPage={isCurrentPage}>
            {label}
          </Item>
        ))}
      </Root>
    </Container>
  );
}
