import { Grid } from '@material-ui/core';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { Error404 } from 'components';
import React, { Suspense } from 'react';
import { RawIntlProvider } from 'react-intl';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { DEFAULT_LANGUAGE } from 'utils/constants';
import intlProvider from '../i18n/provider';

export const BASE_URL = '/distribution-centers';
export const REGISTER_PAGE_URL = `${BASE_URL}/register/:country/:vendorId/:requestedAction/:distributionCenterId?/:stepForm?`;
export const DETAILS_PAGE_URL = `${BASE_URL}/details/:country?/:vendorId?/:deliveryCenterId?`;

const HomeRoute = React.lazy(() => import('../routes/home/HomeRoute'));
const WarehouseDetailsPage = React.lazy(() => import('../pages/WarehouseDetails/WarehouseDetails'));
const RegisterDistributionCenterPage = React.lazy(() =>
  import('../pages/RegisterDistributionCenter/RegisterDistributionCenter')
);

const AppRouter = (): JSX.Element => {
  const { preferredLanguage } = usePreferredLanguageV2();
  const locale = preferredLanguage ?? DEFAULT_LANGUAGE;

  return (
    <BrowserRouter>
      <Suspense fallback="">
        <RawIntlProvider value={intlProvider(locale, true)}>
          <Grid container>
            <Grid item xs={12}>
              <Switch>
                <Route exact path={BASE_URL} component={HomeRoute} />
                <Route path={REGISTER_PAGE_URL} component={RegisterDistributionCenterPage} />
                <Route path={DETAILS_PAGE_URL} component={WarehouseDetailsPage} />
                <Route path="*">
                  <Error404 />
                </Route>
              </Switch>
            </Grid>
          </Grid>
        </RawIntlProvider>
      </Suspense>
    </BrowserRouter>
  );
};
export default AppRouter;
