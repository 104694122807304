import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    error: {
      height: 'calc(100vh - 350px)',
      '& > div > div': {
        width: '150px'
      }
    }
  })
);
