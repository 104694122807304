import { CountriesEU } from 'domains/enums';

export const DISTRIBUTION_CENTER_ENDPOINT = '/api/v1/distribution-center-app-tier';
export const VENDOR_ENDPOINT = '/api/v1/vendor-management-app-tier';
export const COMPANY_MANAGEMENT_BFF = '/api/v1/company-management-bff';

export const SEGMENT_SCREEN_NAME = {
  DISTRIBUTION_CENTER: 'DISTRIBUTION_CENTER',
};

export const EU_COUNTRIES = Object.values(CountriesEU);

export const CODE_200 = 200;
export const CODE_204 = 204;
export const CODE_201 = 201;

export const ZERO = 0;
export const TWO = 2;
export const THREE = 3;

export const SUCCESS_CODE = [CODE_200, CODE_204, CODE_201];

export const DEFAULT_LANGUAGE = 'en-US';

export const DEFAULT_CHARGES = {
  type: 'DELIVERY_DATE_FEE',
  deliveryFee: 0,
};

export const MAX_DELIVERY_FEE = 9999.99;

export const MAX_TOTAL_FOR_FREE_DELIVERY = 999999.99;

export const DISTRIBUTION_CENTER_LAST_STEP_POSITION = 3;

export const DELIVERY_WINDOW_INVALID_NUMBER = 0;
export const MAX_DELIVERY_WINDOW_INPUT_DIGIT = 2;

export const OPTIMIZELY_PROJECT_NAME = 'supplier-portal-warehouse-management-mfe';

export const MAP_ZOOM_LEVEL = 11.5;
export const MINIMUM_VALID_POLYGON_LENGTH = 4;
export const TRIANGLE_VERTICES_LENGTH = 4; // Azure Maps adds initial vertex at the end of the array

export const STEP_INDEXES = {
  SelectCoverageType: 0,
  GeneralInformation: 1,
  Address: 2,
  CoverageAreas: 3,
};

export const STEP_NAMES: Record<number, string> = {
  0: 'Coverage option',
  1: 'General information',
  2: 'Address',
  3: 'Coverage',
};
