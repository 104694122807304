export enum Clusters {
  'Global_US' = 'Global_US',
  'Global_EU' = 'Global_EU',
  'Restricted_US' = 'Restricted_US',
  'Global_DTC' = 'Global_DTC'
}

export enum ClustersEnv {
  'GLOBAL' = 'GLOBAL',
  'DTC' = 'DTC'
}
