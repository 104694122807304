import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(theme =>
  createStyles({
    notFound: {
      display: 'flex',
      height: 'calc(100vh - 56px)',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      '& [class*="error-404"]': {
        width: '100%',
        maxWidth: '450px',
        height: 'auto',
        marginBottom: theme.spacing(4)
      }
    }
  })
);
