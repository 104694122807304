import MessageMap from '../i18n';

const esAR: MessageMap = {
  TEST: {
    TEST_TRANSLATE: 'Traducir {value}',
  },
  DISTRIBUTION_CENTER: {
    CREATE: {
      PAGE_TITLE: 'Agregar nuevo centro de distribución',
      HEADER_MESSAGE: 'Estás agregando un nuevo centro de distribución a esta compañía.',
    },
    COVERAGE_OPTION_STEP: {
      ALERT_MESSAGE:
        'La elección que estás haciendo ahora se aplicará a todos los centros de distribución de esta compañía',
      STEP_LABEL: 'Opción de cobertura',
      STEP_DETAIL:
        'Elige un tipo de cobertura para todos los centros de distribución de esta compañía',
      BY_CITY_LABEL: 'Por estado y ciudad',
      BY_CITY_DESCRIPTION:
        'Selecciona el área de entrega de tu empresa por ciudad y estado según el servicio postal de tu país.',
      BY_STATE_LABEL: 'Por estado y región',
      BY_STATE_DESCRIPTION:
        'Selecciona el área de entrega de tu empresa por región y estado según el servicio postal de tu país.',
      BY_STATE_EXAMPLE: 'Por ejemplo, SP: Capital; SP: Costa etc.',
      BY_ABI_LABEL: 'Por CDD de AB-inBev',
      BY_ABI_DESCRIPTION:
        'Selecciona el área de entrega de tu empresa en función de las áreas ya cubiertas por uno o más CDD de AB-inBev.',
      BY_ABI_EXAMPLE: 'Por ejemplo, SP: CDD Olinda; SP: CDD Suzano etc.',
      BY_CUSTOMIZED_AREA_LABEL: 'Por área personalizada',
      BY_CUSTOMIZED_AREA_DESCRIPTION:
        'Selecciona el área de entrega de tu empresa dibujando una forma personalizada equivalente al área de cobertura.',
    },
    COMMON: {
      CANCEL: 'Cancelar',
      GO_BACK: 'Volver',
      NEXT: 'Siguiente',
      ADD: 'Agregar',
      SUNDAY: 'Domingo',
      MONDAY: 'Lunes',
      TUESDAY: 'Martes',
      WEDNESDAY: 'Miércoles',
      THURSDAY: 'Jueves',
      FRIDAY: 'Viernes',
      SATURDAY: 'Sábado',
      SUBMIT: 'Enviar',
      STATE: 'Estado',
      CITY: 'Ciudad',
      REGION: 'Región',
      INVALID: 'Inválido',
      REQUIRED: 'Obligatorio',
      FIELD_REQUIRED: 'Este campo es obligatorio.',
      FIELD_LETTERS_NUMBERS_HYPHENS: 'Solo se permiten letras, números y guiones.',
      FIELD_INVALID_TAX_ID: 'ID gubernamental inválido.',
      OPTIONAL_TEXT: '(Opcional)',
    },
    GENERAL_INFORMATION_STEP: {
      COMPANY_DDC_ID_LABEL: 'ID de CDD de la compañía',
      COMPANY_DDC_ID_HINT:
        'La identificación que utiliza tu empresa para identificar este CDD internamente',
      STEP_LABEL: 'Información general',
      STEP_DETAIL: 'Ingresa el nombre y el ID gubernamental',
      COMPANY_DDC_ID_PLACEHOLDER: 'Ingresa el ID de CDD de la compañía',
      LEGAL_NAME_LABEL: 'Nombre legal',
      LEGAL_NAME_PLACEHOLDER: 'Ingresa el Nombre legal',
      TRADE_NAME_LABEL: 'Nombre comercial',
      TRADE_NAME_HINT:
        'O “nombre ficticio”, el nombre que el CDD utiliza para identificarse ante el público',
      TRADE_NAME_PLACEHOLDER: 'Ingresa el Nombre comercial',
      TAX_ID_LABEL: 'ID gubernamental',
      TAX_ID_PLACEHOLDER: 'Ingresa el ID gubernamental',
    },
    ADDRESS_STEP: {
      COUNTRY_LABEL: 'País',
      STEP_LABEL: 'Dirección',
      STEP_DETAIL: 'Agrega información de la ubicación',
      STATE_LABEL: 'Estado',
      ZIP_CODE_LABEL: 'Código postal',
      ZIP_CODE_PLACEHOLDER: 'Ingresa el Código postal',
      CITY_LABEL: 'Ciudad',
      CITY_PLACEHOLDER: 'Ingresa la Ciudad',
      OPERATION_ADDRESS_LABEL: 'Dirección operativa',
      OPERATION_ADDRESS_PLACEHOLDER: 'Ingresa la Dirección operativa',
    },
    COVERAGE_DETAILS_STEP: {
      ALERT_MESSAGE: 'Para crear un nuevo CD, agrega al menos un área de cobertura.',
      STEP_LABEL: 'Datos de la cobertura',
      STEP_DETAIL: 'Establece el área de cobertura y el rango de entrega',
      COVERAGE_OPTION_TOOLTIP:
        'El tipo de cobertura se selecciona al agregar un centro de distribución.',
      BY_CUSTOMIZED_AREA: 'Por área personalizada',
      COVERAGE_TITLE: 'Área de cobertura',
      COVERAGE_LABEL: 'Área de cobertura {number}',
      SET_AREA: 'Establecer área',
      REMOVE_AREA: 'Quitar área',
      DELIVERY_RANGE_LABEL: 'Rango de entrega',
      DELIVERY_RANGE_DESCRIPTION:
        'Considera la cantidad de tiempo requerido desde el análisis del pedido hasta la entrega. \nAsegúrate de ingresar los valores de mínimo a máximo.',
      DELIVERY_RANGE_FROM: 'Desde',
      DELIVERY_RANGE_TO: 'hasta',
      DELIVERY_RANGE_CALENDAR_DAYS: 'días del calendario hasta la entrega',
      DELIVERY_RANGE_INVALID_MESSAGE: 'El número mínimo debe ser menor que el número máximo.',
      DELIVERY_DATES_LABEL: 'Días de entrega',
      DELIVERY_DATES_DESCRIPTION:
        'Establece los días de la semana disponibles para la entrega para que el cliente pueda saber cuándo llegará el pedido con mayor precisión.',
      ADD_ANOTHER_AREA: 'Agregar otra área',
      EDIT_MODAL_TITLE: 'Editar área de cobertura',
      SET_MODAL_TITLE: 'Establecer área de cobertura',
      EDIT_AREA: 'Editar área',
      EDIT_AREA_TITLE:
        'Dibuja una forma personalizada con límites para definir un área de cobertura de entrega y haz doble clic para cerrarla. Las formas no pueden superponerse',
      DRAW_POLYGON_MESSAGE:
        'Crea un área de cobertura dibujando una forma a través de los puntos de anclaje',
      AREA_TEXT: 'Área #{number}',
      DELIVERY_FEE_LABEL: 'Tarifa de entrega',
      DELIVERY_FEE_DESCRIPTION: 'Si se establece, se aplicará a todas las áreas de cobertura.',
      DELIVERY_FEE_INPUT_LABEL: 'Tarifa de entrega total por pedido',
      MINIMUM_FOR_FREE_DELIVERY_INPUT_LABEL: 'Total mínimo para entrega gratuita',
    },
    NOTIFICATION: {
      CREATION_SUCCESS: 'El centro de distribución se creó correctamente.',
      EDITION_SUCCESS: 'El centro de distribución se ha actualizado correctamente.',
      DELETION_SUCCESS: 'El centro de distribución se eliminó correctamente.',
      DELETION_FAILURE:
        'No se pudo eliminar el CDD debido a un error del sistema. Inténtalo de nuevo más tarde.',
      REMOVE_COVERAGE_AREA_SUCCESS: 'El área de cobertura {index} se eliminó correctamente.',
    },
    DETAILS: {
      COVERAGE_AND_SHIPMENT: 'Cobertura y envío',
      EDIT_COVERAGE_AREAS: 'Editar áreas de cobertura',
      EDIT_DISTRIBUTION_CENTER: 'Editar centro de distribución',
      DELETE_DISTRIBUTION_CENTER: 'Eliminar centro de distribución',
      DISTRIBUTION_CENTER_INFORMATION: 'Información del centro de distribución',
      DELIVERY_DATES_TITLE: 'Días de entrega',
      COVERAGE_AREA_TITLE: 'Área de cobertura {number}',
      DELIVERY_RANGE_TITLE: 'Rango de entrega',
      COVERAGE_TYPE_TITLE: 'Tipo de cobertura',
      ADDITIONAL_NAMES_LABEL: `y {itemCount, plural,
        one {otra 1 área}
        other {{itemCount} otras áreas}
      }.`,
      CUSTOMIZED_AREA_LABEL: 'Área personalizada {number}',
      DELIVERY_RANGE_LABEL: 'Desde {from} hasta {to} días del calendario hasta la entrega.',
      DDC_ID_TITLE: 'ID de CDD',
      COMPANY_DDC_ID_TITLE: 'ID de CDD de la compañía',
      LEGAL_NAME_TITLE: 'Nombre legal',
      TRADE_NAME_TITLE: 'Nombre comercial',
      OPERATIONAL_ADDRESS_TITLE: 'Dirección operativa',
      COUNTRY_TITLE: 'País',
      TAX_ID_TITLE: 'ID gubernamental',
      CITY_TITLE: 'Ciudad',
      DELIVERY_FEE_TITLE: 'Tarifa de entrega total por pedido',
      MINIMUM_FOR_FREE_DELIVERY: 'Total mínimo para entrega gratuita',
      DISTRIBUTION_CENTER_COVERAGE_INFORMATION: 'Información de cobertura',
      ACCOUNT_NUMBER: 'Número de cuentas alcanzadas',
    },
    EDIT: {
      PAGE_TITLE: 'Editar centro de distribución',
      HEADER_MESSAGE: 'Estás editando este centro de distribución.',
    },
    CANCELLATION_MODAL: {
      CANCEL: 'No, continuar editando',
      CONFIRM: 'Sí, cancelar',
      TITLE: '¿Usted estás seguro de que desea cancelar?',
      DESCRIPTION:
        'Todas las informaciones que no se hayan guardado se perderán y no se podrán recuperar.',
    },
    DELETE_DISTRIBUTION_CENTER_MODAL: {
      TITLE: 'Eliminar centro de distribución',
      DESCRIPTION:
        'Se eliminará toda la información sobre "{ddc}". No puedes deshacer esta acción.',
      CONFIRM: 'Sí, eliminar',
      CANCEL: 'No, cancelar',
    },
    DELETE_COVERAGE_AREA_MODAL: {
      TITLE: 'Eliminar el área de cobertura',
      DESCRIPTION: '¿Está seguro de que desea eliminar el área de cobertura {index}?',
      CONFIRM: 'Eliminar',
    },
  },
  LIST_PAGE: {
    TITLE: 'Centros de distribución',
    BUTTONDDC: 'Registrar un nuevo CDD',
    TABLE_HEADER_1: 'Centro de distribución',
    TABLE_HEADER_2: 'Ciudad',
    TABLE_HEADER_3: 'Estado',
    TABLE_HEADER_4: 'Área de cobertura',
    TABLE_HEADER_5: 'Previsión de entrega',
    TABLE_HEADER_6: 'Empresa',
    TABLE_HEADER_7: 'Modelo de Servicio',
    ROWS: 'Ítems por página',
    RANGES: 'intervalos',
    BUSINESS_DAYS: 'días',
    TO: 'a ',
    AREAS: '{numberOfAreas} areas',
    CUSTOM_AREA: '{numberOfCustomAreas} custom area',
    CUSTOM_AREAS: '{numberOfCustomAreas} custom areas',
  },
  FILTER_BY_COUNTRY: {
    LABEL: 'Filtrar por pais',
  },
  COUNTRIES: {
    AR: 'Argentina',
    BR: 'Brasil',
    CA: 'Canadá',
    CO: 'Colombia',
    DO: 'República Dominicana',
    EC: 'Ecuador',
    ES: 'Islas Canarias',
    HN: 'Honduras',
    KR: 'Corea del Sur',
    MX: 'México',
    PA: 'Panamá',
    PE: 'Perú',
    PY: 'Paraguay',
    SV: 'El Salvador',
    ZA: 'Sudáfrica',
  },
  REGISTRATION_DDC_MODAL: {
    TITLE: 'Registrar nuevo centro de distribución',
    TITLE_SUBTITLE: 'Estás agregando un nuevo centro de distribución a  ',
    STEPPER_TITLE_1: 'Informaciones del centro de distribución',
    STEPPER_TITLE_2: 'Área de cobertura y entrega',
    LABEL_REQUIRED: 'Este campo es obligatorio.',
    LABEL_REQUIRED_MULTI_FIELDS: 'Estos campos son obligatorios.',
    BUTTON_CANCEL: 'CANCELAR',
    BUTTON_NEXT: 'PRÓXIMO',
    INVALID_GOV_ID: 'Identificación gubernamental inválida',
  },
  REGISTRATION_DDC_FIRST_FLOW: {
    SUBTITLE: 'Informaciones del centro de distribución',
    TIP: 'Registra un nuevo centro de distribución completando las informaciones solicitadas.',
    LABEL_NAME: 'Razón Social',
    LABEL_TRADE: 'Nombre comercial',
    LABEL_TRADE_INFO: 'El "nombre fantasía", el nombre público que tu CDD utiliza públicamente.',
    IDENTIFIER: 'Identificación gubernamental',
    IDENTIFIER_INFO: 'El número de caracteres puede variar, dependiendo el país de origen.',
    LABEL_ADDRESS: 'Domicilio de la operación',
    LABEL_CITY: 'Ciudad',
    LABEL_STATE: 'Estado',
    LABEL_ZIP_CODE: 'Código postal',
    LABEL_COUNTRY: 'País',
  },
  REGISTRATION_DDC_SECOND_FLOW: {
    TITLE: 'Área de cobertura y entrega',
    TITLE_INFORMATION:
      'Las informaciones acerca de la cobertura y la entrega garantizarán que tus pedidos sean recibidos y preparados en el momento preciso por el centro de distribución.',
    LABEL_COVERAGE: 'Área de cobertura',
    LABEL_COVERAGE_INFO:
      'Si un estado/ciudad ya fue seleccionado por otro CDD, estos no aparecerán disponibles.',
    LABEL_STATE: 'Estado',
    LABEL_CITY: 'Ciudad',
    LABEL_ABI_DDC: 'Ab-Inbev CDD',
    LABEL_EXPECTED_DAYS: 'Previsión para la entrega de pedidos',
    LABEL_EXPECTED_DAYS_INFO:
      'Considera el tiempo necesario desde el análisis del pedido hasta la entrega. Asegúrate de ingresar los valores de mínimo a máximo.',
    LABEL_FROM: 'De',
    LABEL_TO: 'a',
    LABEL_DELIVERY: 'días hasta la entrega',
    LABEL_ADD_AREA: 'REGISTRAR UNA NUEVA ÁREA',
    LABEL_BUTTON_BACK: 'VOLVER',
    LABEL_BUTTON_ADD: 'AGREGAR',
  },
  CONFIRMATION_MODAL: {
    TITLE: '¿Seguro?',
    CONTENT:
      'Al cerrar esta ventana, perderás las informaciones que no se han guardado. ¿Aún así deseas cerrar?',
    BUTTON_CANCEL: 'CANCELAR',
    BUTTON_CLOSE: 'CERRAR',
  },
  REGISTRATION_FINISH_MODAL: {
    TITLE: 'Antes de terminar...',
    SUBTITLE:
      'Asegúrate de que todas las informaciones estén correctas. No será posible editar o borrar más adelante en el proceso. ',
    LABEL_BUTTON_BACK: 'VOLVER',
    LABEL_BUTTON_SAVE: 'GUARDAR',
  },
  REGISTER_DDC: {
    FEEDBACK_TOAST_SUCCESS: 'Un nuevo CDD fue registrado ',
    LABEL_ERROR_TITLE: '¡Algo falló!',
    LABEL_ERROR_SUBTITLE:
      'Lo lamentamos, pero estamos con dificultades técnicas. Intenta de nuevo más tarde.',
    BUTTON_BACK: 'VOLVER',
    BUTTON_CLOSE: 'CERRAR',
  },
  BREADCRUMB: {
    TITLE_PAGE: 'Centros de distribución',
    COMPANY_DETAILS: 'Datos de la compañía',
  },
  DDC_REGISTRATION_COVERAGE_AREA_STEP: {
    SUBTITLE_EDIT_POLYGON: 'Create a coverage area by drawing a shape through anchor points.',
    DRAW_POLYGON: 'Draw custom shape by polygon',
    EDIT_POINTS: 'Edit points',
    DELETE_AREA: 'Delete area',
  },
  COMPONENTS: {
    NOT_FOUND_HEADER_TEXT: 'Mmm… Parece que esta página se ha ido',
    NOT_FOUND_SUBHEADER_TEXT: 'Parece que esta página no se encontró o no existe.',
    NOT_FOUND_HOME_BTN: 'Volver',
    ERROR_GENERIC: 'Algo salió mal',
  },
  SEARCH_AREA: {
    PLACEHOLDER: 'Search by state, city, and others',
    EMPTY_LIST_MESSAGE: 'No results found',
    ERROR: 'An error occurred while searching. Please try again later.',
  },
  ENTITY_TYPE: {
    Country: 'Country name',
    CountrySecondarySubdivision: 'County',
    CountrySubdivision: 'State or Province',
    CountryTertiarySubdivision: 'Named Area',
    Municipality: 'City/Town',
    MunicipalitySubdivision: 'Sub/Super City',
    Neighbourhood: 'Neighbourhood',
    PostalCodeArea: 'Postal Code/Zip Code',
  },
};

export default esAR;
