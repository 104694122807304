import { Alert } from '@hexa-ui/components';
import { styled } from 'theme';

export const AlertStyled = styled(Alert, {
  flexBasis: '100%',
  marginTop: '$$marginTop',
  marginBottom: '$$marginBottom',
  '& > div': {
    alignItems: 'center',
    '& div': {
      lineHeight: 1.5
    }
  }
});
