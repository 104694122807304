import {
  createFeatureToggleServiceV2,
  useFeatureToggleServiceV2 as featureToggleService
} from 'admin-portal-shared-services';
import { useEffect, useState } from 'react';

import { UseInitializeFeatureToggleResult } from 'hooks';
import { OPTIMIZELY_PROJECT_NAME } from 'utils/constants';

const isFeatureToggleServiceLoaded = () =>
  window.services?.FeatureToggleOptimizelyV2 &&
  // @ts-expect-error allow type any for services object
  window.services?.FeatureToggleOptimizelyV2[OPTIMIZELY_PROJECT_NAME];

export const useInitializeFeatureToggle = (
  optimizelyKey: string
): UseInitializeFeatureToggleResult => {
  const [isReady, setIsReady] = useState<boolean>(false);

  const handleShowingWarehousePage = (): void => {
    featureToggleService(OPTIMIZELY_PROJECT_NAME)
      .onReady()
      .then(() => {
        setIsReady(true);
      });
  };

  useEffect(() => {
    if (isFeatureToggleServiceLoaded()) {
      handleShowingWarehousePage();
      return;
    }

    createFeatureToggleServiceV2(OPTIMIZELY_PROJECT_NAME, optimizelyKey);
    handleShowingWarehousePage();
  }, [optimizelyKey]);

  return {
    isReady
  };
};
