import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  TEST: {
    TEST_TRANSLATE: 'Traduzir {value}',
  },
  DISTRIBUTION_CENTER: {
    CREATE: {
      PAGE_TITLE: 'Adicionar novo centro de distribuição',
      HEADER_MESSAGE: 'Você está adicionando um novo centro de distribuição a esta empresa.',
    },
    COVERAGE_OPTION_STEP: {
      ALERT_MESSAGE:
        'A escolha que você está fazendo agora será aplicada a todos os centros de distribuição desta empresa.',
      STEP_LABEL: 'Opção de cobertura',
      STEP_DETAIL:
        'Escolha um tipo de cobertura para todos os centros de distribuição desta empresa',
      BY_CITY_LABEL: 'Por estado e cidade',
      BY_CITY_DESCRIPTION:
        'Selecione a área de entrega da sua empresa por cidade e estado de acordo com o serviço postal do seu país.',
      BY_STATE_LABEL: 'Por estado e região',
      BY_STATE_DESCRIPTION:
        'Selecione a área de entrega da sua empresa por região e estado de acordo com o serviço postal do seu país.',
      BY_STATE_EXAMPLE: 'Exemplo: SP - Capital, SP - Litoral, etc.',
      BY_ABI_LABEL: 'Por CDD da AB-inBev',
      BY_ABI_DESCRIPTION:
        'Selecione a área de entrega da sua empresa com base em áreas já cobertas por um ou mais CDDs da AB-inBev.',
      BY_ABI_EXAMPLE: 'Exemplo: SP - CDD Olinda, SP - CDD Suzano, etc.',
      BY_CUSTOMIZED_AREA_LABEL: 'Por área personalizada',
      BY_CUSTOMIZED_AREA_DESCRIPTION:
        'Selecione a área de entrega da sua empresa desenhando uma forma personalizada equivalente à área de cobertura.',
    },
    COMMON: {
      CANCEL: 'Cancelar',
      GO_BACK: 'Voltar',
      NEXT: 'Próximo',
      ADD: 'Adicionar',
      SUNDAY: 'Domingo',
      MONDAY: 'Segunda',
      TUESDAY: 'Terça',
      WEDNESDAY: 'Quarta',
      THURSDAY: 'Quinta',
      FRIDAY: 'Sexta',
      SATURDAY: 'Sábado',
      SUBMIT: 'Enviar',
      STATE: 'Estado',
      CITY: 'Cidade',
      REGION: 'Região',
      INVALID: 'Inválido',
      REQUIRED: 'Obrigatório',
      FIELD_REQUIRED: 'Este campo é obrigatório.',
      FIELD_LETTERS_NUMBERS_HYPHENS: 'Este campo deve conter apenas letras, números e hífens.',
      FIELD_INVALID_TAX_ID: 'Este CNPJ é inválido.',
      OPTIONAL_TEXT: '(Opcional)',
    },
    GENERAL_INFORMATION_STEP: {
      COMPANY_DDC_ID_LABEL: 'Código do CDD da empresa',
      COMPANY_DDC_ID_HINT: 'O código que sua empresa usa para identificar este CDD internamente',
      STEP_LABEL: 'Informações gerais',
      STEP_DETAIL: 'Insira o nome e o CNPJ',
      COMPANY_DDC_ID_PLACEHOLDER: 'Insira o Código do CDD da empresa',
      LEGAL_NAME_LABEL: 'Razão social',
      LEGAL_NAME_PLACEHOLDER: 'Insira a Razão social',
      TRADE_NAME_LABEL: 'Nome comercial',
      TRADE_NAME_HINT: 'Ou "nome fantasia", o nome que o CDD usa para se identificar ao público',
      TRADE_NAME_PLACEHOLDER: 'Insira o Nome comercial',
      TAX_ID_LABEL: 'CNPJ',
      TAX_ID_PLACEHOLDER: 'Insira o CNPJ',
    },
    ADDRESS_STEP: {
      COUNTRY_LABEL: 'País',
      STEP_LABEL: 'Endereço',
      STEP_DETAIL: 'Adicionar informações de localização',
      STATE_LABEL: 'Estado',
      ZIP_CODE_LABEL: 'CEP',
      ZIP_CODE_PLACEHOLDER: 'Insira o CEP',
      CITY_LABEL: 'Cidade',
      CITY_PLACEHOLDER: 'Insira a Cidade',
      OPERATION_ADDRESS_LABEL: 'Endereço operacional',
      OPERATION_ADDRESS_PLACEHOLDER: 'Insira o Endereço operacional',
    },
    COVERAGE_DETAILS_STEP: {
      ALERT_MESSAGE: 'Para criar um novo CD, adicione pelo menos uma área de cobertura.',
      STEP_LABEL: 'Detalhes da cobertura',
      STEP_DETAIL: 'Defina a área de cobertura e o prazo de entrega',
      COVERAGE_OPTION_TOOLTIP:
        'O tipo de cobertura é selecionado ao adicionar um centro de distribuição.',
      BY_CUSTOMIZED_AREA: 'Por área personalizada',
      COVERAGE_TITLE: 'Área de cobertura',
      COVERAGE_LABEL: 'Área de cobertura {number}',
      SET_AREA: 'Definir área',
      REMOVE_AREA: 'Remover área',
      DELIVERY_RANGE_LABEL: 'Prazo de entrega',
      DELIVERY_RANGE_DESCRIPTION:
        'Considere o tempo necessário desde a análise do pedido até a entrega. \nVerifique se você inseriu os valores do mínimo ao máximo.',
      DELIVERY_RANGE_FROM: 'De',
      DELIVERY_RANGE_TO: 'para',
      DELIVERY_RANGE_CALENDAR_DAYS: 'dias corridos até a entrega',
      DELIVERY_RANGE_INVALID_MESSAGE: 'O valor mínimo deve ser menor que o valor máximo.',
      DELIVERY_DATES_LABEL: 'Dias de entrega',
      DELIVERY_DATES_DESCRIPTION:
        'Defina os dias da semana disponíveis para entrega, para que o cliente possa saber quando o pedido feito chegará com mais precisão.',
      ADD_ANOTHER_AREA: 'Adicionar outra área',
      EDIT_MODAL_TITLE: 'Editar área de cobertura',
      SET_MODAL_TITLE: 'Definir área de cobertura',
      EDIT_AREA: 'Editar área',
      EDIT_AREA_TITLE:
        'Desenhe uma forma personalizada com limites para definir uma área de cobertura de entrega e clique duas vezes para fechá-la. As formas não podem se sobrepor',
      DRAW_POLYGON_MESSAGE: 'Crie uma área de cobertura desenhando uma forma através de vértices',
      AREA_TEXT: 'Área #{number}',
      DELIVERY_FEE_LABEL: 'Taxa de entrega',
      DELIVERY_FEE_DESCRIPTION: 'Se definida, será aplicada a todas as áreas de cobertura.',
      DELIVERY_FEE_INPUT_LABEL: 'Taxa de entrega por pedido',
      MINIMUM_FOR_FREE_DELIVERY_INPUT_LABEL: 'Total mínimo para entrega gratuita',
    },
    NOTIFICATION: {
      CREATION_SUCCESS: 'Centro de distribuição criado com sucesso.',
      EDITION_SUCCESS: 'Centro de distribuição editado com sucesso.',
      DELETION_SUCCESS: 'Centro de distribuição excluído com sucesso.',
      DELETION_FAILURE:
        'Não foi possível excluir o CDD devido a um erro do sistema. Tente novamente mais tarde.',
      REMOVE_COVERAGE_AREA_SUCCESS: 'A área de cobertura {index} foi removida com sucesso.',
    },
    DETAILS: {
      COVERAGE_AND_SHIPMENT: 'Cobertura e envio',
      EDIT_COVERAGE_AREAS: 'Editar áreas de cobertura',
      EDIT_DISTRIBUTION_CENTER: 'Editar centro de distribuição',
      DELETE_DISTRIBUTION_CENTER: 'Excluir centro de distribuição',
      DISTRIBUTION_CENTER_INFORMATION: 'Informações do centro de distribuição',
      DELIVERY_DATES_TITLE: 'Dias de entrega',
      COVERAGE_AREA_TITLE: 'Área de cobertura {number}',
      DELIVERY_RANGE_TITLE: 'Prazo de entrega',
      COVERAGE_TYPE_TITLE: 'Tipo de cobertura',
      ADDITIONAL_NAMES_LABEL: `e mais {itemCount, plural,
        one {1 área}
        other {{itemCount} áreas}
      }.`,
      CUSTOMIZED_AREA_LABEL: 'Área personalizada {number}',
      DELIVERY_RANGE_LABEL: 'De {from} a {to} dias corridos até a entrega',
      DDC_ID_TITLE: 'Código do CDD',
      COMPANY_DDC_ID_TITLE: 'Código do CDD da Empresa',
      LEGAL_NAME_TITLE: 'Razão social',
      TRADE_NAME_TITLE: 'Nome comercial',
      OPERATIONAL_ADDRESS_TITLE: 'Endereço operacional',
      COUNTRY_TITLE: 'País',
      TAX_ID_TITLE: 'CNPJ',
      CITY_TITLE: 'Cidade',
      DELIVERY_FEE_TITLE: 'Total da taxa de entrega por pedido',
      MINIMUM_FOR_FREE_DELIVERY: 'Total mínimo para entrega gratuita',
      DISTRIBUTION_CENTER_COVERAGE_INFORMATION: 'Informações de cobertura',
      ACCOUNT_NUMBER: 'Número de contas alcançadas',
    },
    EDIT: {
      PAGE_TITLE: 'Editar centro de distribuição',
      HEADER_MESSAGE: 'Você está editando este centro de distribuição',
    },
    CANCELLATION_MODAL: {
      CANCEL: 'Não, continuar editando',
      CONFIRM: 'Sim, cancelar',
      TITLE: 'Você tem certeza que deseja cancelar?',
      DESCRIPTION: 'Todas as informações alteradas serão perdidas e não poderão ser recuperadas.',
    },
    DELETE_DISTRIBUTION_CENTER_MODAL: {
      TITLE: 'Excluir centro de distribuição',
      DESCRIPTION:
        'Todas as informações sobre "{ddc}" serão excluídas. Você não pode desfazer esta ação.',
      CONFIRM: 'Sim, excluir',
      CANCEL: 'Não, cancelar',
    },
    DELETE_COVERAGE_AREA_MODAL: {
      TITLE: 'Remover área de cobertura',
      DESCRIPTION: 'Tem certeza de que deseja remover a área de cobertura {index}?',
      CONFIRM: 'Remover',
    },
  },
  LIST_PAGE: {
    TITLE: 'Centros de distribuição',
    BUTTONDDC: 'Novo CDD',
    TABLE_HEADER_1: 'Centro de distribuição',
    TABLE_HEADER_2: 'Cidade',
    TABLE_HEADER_3: 'Estado',
    TABLE_HEADER_4: 'Área de cobertura',
    TABLE_HEADER_5: 'Previsão de entrega',
    TABLE_HEADER_6: 'Empresa',
    TABLE_HEADER_7: 'Modelo de serviço',
    ROWS: 'Itens por página',
    RANGES: 'intervalos',
    BUSINESS_DAYS: 'dias',
    TO: 'a ',
    AREAS: '{numberOfAreas} áreas',
    CUSTOM_AREA: '{numberOfCustomAreas} custom area',
    CUSTOM_AREAS: '{numberOfCustomAreas} custom areas',
  },
  FILTER_BY_COUNTRY: {
    LABEL: 'Filtrar por país',
  },
  COUNTRIES: {
    AR: 'Argentina',
    BR: 'Brasil',
    CA: 'Canadá',
    CO: 'Colômbia',
    DO: 'República Dominicana',
    EC: 'Equador',
    ES: 'Ilhas Canárias',
    HN: 'Honduras',
    KR: 'Coréia do Sul',
    MX: 'México',
    PA: 'Panamá',
    PE: 'Peru',
    PY: 'Paraguai',
    SV: 'El Salvador',
    ZA: 'África do Sul',
  },
  REGISTRATION_DDC_MODAL: {
    TITLE: 'Cadastrar um novo centro de distribuição',
    TITLE_SUBTITLE: 'Você está cadastrando um novo centro de distribuição a ',
    STEPPER_TITLE_1: 'Informações do centro de distribuição',
    STEPPER_TITLE_2: 'Área de cobertura e entrega',
    LABEL_REQUIRED: 'Preenchimento obrigatório.',
    LABEL_REQUIRED_MULTI_FIELDS: 'Preenchimento obrigatório.',
    BUTTON_CANCEL: 'CANCELAR',
    BUTTON_NEXT: 'PRÓXIMO',
    INVALID_GOV_ID: 'CNPJ Inválido',
  },
  REGISTRATION_DDC_FIRST_FLOW: {
    SUBTITLE: 'Informações do centro de distribuição',
    TIP: 'Cadastre seu novo centro de distribuição preenchendo todas as informações necessárias.',
    LABEL_NAME: 'Razão social',
    LABEL_TRADE: 'Nome comercial',
    LABEL_TRADE_INFO:
      'Ou "Nome fantasia" é o nome dado para o CDD e que será conhecido pelo seu público',
    IDENTIFIER: 'CNPJ',
    IDENTIFIER_INFO: 'O número de caracteres pode variar dependendo do país de origem.',
    LABEL_ADDRESS: 'Endereço de operação',
    LABEL_CITY: 'Cidade',
    LABEL_STATE: 'Estado',
    LABEL_ZIP_CODE: 'CEP',
    LABEL_COUNTRY: 'País',
  },
  REGISTRATION_DDC_SECOND_FLOW: {
    TITLE: 'Área de cobertura e entrega',
    TITLE_INFORMATION:
      'As informações sobre cobertura e entrega irão garantir que seus pedidos sejam recebidos e preparados no momento certo pelo centro de distribuição.',
    LABEL_COVERAGE: 'Área de cobertura',
    LABEL_COVERAGE_INFO:
      'Se um estado/cidade já tiver sido selecionado por outro CDD, ele não aparecerá disponível.',
    LABEL_STATE: 'Estado',
    LABEL_CITY: 'Cidade',
    LABEL_ABI_DDC: 'Ab-Inbev CDD',
    LABEL_EXPECTED_DAYS: 'Previsão para entrega de pedidos',
    LABEL_EXPECTED_DAYS_INFO:
      'Considere o tempo necessário desde a análise do pedido até a entrega. Certifique-se de inserir os valores do mínimo ao máximo.',
    LABEL_FROM: 'De',
    LABEL_TO: 'a',
    LABEL_DELIVERY: 'dias até a entrega',
    LABEL_ADD_AREA: 'CADASTRAR UMA NOVA ÁREA',
    LABEL_BUTTON_BACK: 'VOLTAR',
    LABEL_BUTTON_ADD: 'ADICIONAR',
  },
  CONFIRMATION_MODAL: {
    TITLE: 'Tem certeza?',
    CONTENT:
      'Ao fechar essa janela, você perderá informações não salvas! Deseja fechar mesmo assim?',
    BUTTON_CANCEL: 'CANCELAR',
    BUTTON_CLOSE: 'FECHAR',
  },
  REGISTRATION_FINISH_MODAL: {
    TITLE: 'Antes de terminar...',
    SUBTITLE:
      'Certifique-se de revisar todas as informações. Não será possível editar ou deletar depois.',
    LABEL_BUTTON_BACK: 'VOLTAR',
    LABEL_BUTTON_SAVE: 'SALVAR',
  },
  REGISTER_DDC: {
    FEEDBACK_TOAST_SUCCESS: 'Um novo CDD foi cadastrado',
    LABEL_ERROR_TITLE: 'Algo deu errado!',
    LABEL_ERROR_SUBTITLE:
      'Lamentamos, mas estamos com problemas técnicos. Tente novamente mais tarde.',
    BUTTON_BACK: 'VOLTAR',
    BUTTON_CLOSE: 'FECHAR',
  },
  BREADCRUMB: {
    TITLE_PAGE: 'Centros de distribuição',
    COMPANY_DETAILS: 'Detalhes da empresa',
  },
  DDC_REGISTRATION_COVERAGE_AREA_STEP: {
    SUBTITLE_EDIT_POLYGON: 'Create a coverage area by drawing a shape through anchor points.',
    DRAW_POLYGON: 'Draw custom shape by polygon',
    EDIT_POINTS: 'Edit points',
    DELETE_AREA: 'Delete area',
  },
  COMPONENTS: {
    NOT_FOUND_HEADER_TEXT: 'Mmm… Parece que esta página sumiu',
    NOT_FOUND_SUBHEADER_TEXT: 'Parece que esta página não foi encontrada ou não existe.',
    NOT_FOUND_HOME_BTN: 'Voltar',
    ERROR_GENERIC: 'Algo deu errado',
  },
  SEARCH_AREA: {
    PLACEHOLDER: 'Search by state, city, and others',
    EMPTY_LIST_MESSAGE: 'No results found',
    ERROR: 'An error occurred while searching. Please try again later.',
  },
  ENTITY_TYPE: {
    Country: 'Country name',
    CountrySecondarySubdivision: 'County',
    CountrySubdivision: 'State or Province',
    CountryTertiarySubdivision: 'Named Area',
    Municipality: 'City/Town',
    MunicipalitySubdivision: 'Sub/Super City',
    Neighbourhood: 'Neighbourhood',
    PostalCodeArea: 'Postal Code/Zip Code',
  },
};

export default ptBR;
