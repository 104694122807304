import React, { createContext, useMemo, useState } from 'react';
import { MapAuthContextData, MapAuthProviderParams } from 'context/types';

export const MapAuth = createContext<MapAuthContextData>({} as MapAuthContextData);

export const MapAuthProvider = ({
  children,
  mapsPrimaryKey,
  mapsClientId
}: MapAuthProviderParams): JSX.Element => {
  const [azureMapsClientId] = useState<string>(mapsClientId);
  const [azureMapsAccountPrimaryKey] = useState<string>(mapsPrimaryKey);

  const providerValues = useMemo(
    () => ({
      azureMapsAccountPrimaryKey,
      azureMapsClientId
    }),
    [azureMapsAccountPrimaryKey, azureMapsClientId]
  );

  return <MapAuth.Provider value={providerValues}>{children}</MapAuth.Provider>;
};
