import React, { useState } from 'react';
import {
  CssBaseline,
  StylesProvider,
  ThemeProvider,
  createGenerateClassName
} from '@material-ui/core';
import AppRouter from 'bootstrap/AppRouter';
import { ClusterProvider } from 'context/cluster';
import { MapAuthProvider } from 'context/mapAuth';
import { ClustersEnv } from 'domains/Clusters.d';
import { useInitializeFeatureToggle } from 'hooks/useInitializeFeatureToggle';
import useSegmentAnalytics from 'hooks/useSegmentAnalytics';
import { defaultTheme } from 'supplier-portal-shared-components';
import { configureStitches } from 'theme';

type AppProps = {
  optimizelyKey: string;
  segmentKey: string;
  azureMapsAccountPrimaryKey: string;
  azureMapsClientId: string;
  cluster: ClustersEnv;
};

function App({
  optimizelyKey,
  segmentKey,
  azureMapsAccountPrimaryKey,
  azureMapsClientId,
  cluster
}: AppProps): JSX.Element {
  useState(configureStitches());

  const generateClassName = createGenerateClassName({
    productionPrefix: 'warehouse',
    seed: 'warehouse'
  });

  useSegmentAnalytics(segmentKey);

  const { isReady: showWarehouseModule } = useInitializeFeatureToggle(optimizelyKey);

  if (!showWarehouseModule) {
    return <></>;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <StylesProvider generateClassName={generateClassName}>
        <ClusterProvider clusterEnv={cluster}>
          <CssBaseline />
          <MapAuthProvider
            mapsClientId={azureMapsClientId}
            mapsPrimaryKey={azureMapsAccountPrimaryKey}
          >
            <AppRouter />
          </MapAuthProvider>
        </ClusterProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
