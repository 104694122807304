import { Card, IconButton, Paragraph } from '@hexa-ui/components';
import { styled } from 'theme';

export const Container = styled('div', {
  position: 'relative',
  display: 'flex'
});

export const OptionsButton = styled(IconButton, {
  position: 'absolute',
  backgroundColor: '$neutral20',
  top: '$4',
  right: '$4',
  zIndex: 1
});

export const DropdownContainer = styled(Card, {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  position: 'absolute',
  top: '$14',
  right: '$4',
  zIndex: 2
});

export const DropdownItem = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$2',
  padding: '0 $4',
  height: '56px',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '$neutral20'
  }
});

export const DropdownText = styled(Paragraph, {
  whiteSpace: 'nowrap'
});
