import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  TEST: {
    TEST_TRANSLATE: 'Translate {value}',
  },
  DISTRIBUTION_CENTER: {
    CREATE: {
      PAGE_TITLE: 'Add new distribution center',
      HEADER_MESSAGE: 'You are adding a new distribution center to this company.',
    },
    COVERAGE_OPTION_STEP: {
      ALERT_MESSAGE:
        "The choice you're making now will hold for all distributions centers of this company.",
      STEP_LABEL: 'Coverage option',
      STEP_DETAIL: 'Choose a type of coverage for all distribution centers of this company',
      BY_CITY_LABEL: 'By state and city',
      BY_CITY_DESCRIPTION:
        "Select your company's delivery area by city and state according to your country's postal service.",
      BY_STATE_LABEL: 'By state and region',
      BY_STATE_DESCRIPTION:
        "Select your company's delivery area by region and state according to your country's postal service.",
      BY_STATE_EXAMPLE: 'Ex.: SP - Capital, SP - Coast etc.',
      BY_ABI_LABEL: 'By AB-inBev DDC',
      BY_ABI_DESCRIPTION:
        "Select your company's delivery area based on areas already covered by one or more AB-inBev DDC's.",
      BY_ABI_EXAMPLE: 'Ex.: SP - CDD Olinda, SP - CDD Suzano etc.',
      BY_CUSTOMIZED_AREA_LABEL: 'By customized area',
      BY_CUSTOMIZED_AREA_DESCRIPTION:
        "Select your company's delivery area by drawing a custom shape equivalent to the coverage area.",
    },
    COMMON: {
      CANCEL: 'Cancel',
      GO_BACK: 'Go back',
      NEXT: 'Next',
      ADD: 'Add',
      SUNDAY: 'Sunday',
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      SATURDAY: 'Saturday',
      SUBMIT: 'Submit',
      STATE: 'State',
      CITY: 'City',
      REGION: 'Region',
      INVALID: 'Invalid',
      REQUIRED: 'Required',
      FIELD_REQUIRED: 'This field is required.',
      FIELD_LETTERS_NUMBERS_HYPHENS: 'Only letters, numbers and hyphens are allowed.',
      FIELD_INVALID_TAX_ID: 'Invalid Government ID.',
      OPTIONAL_TEXT: '(Optional)',
    },
    GENERAL_INFORMATION_STEP: {
      COMPANY_DDC_ID_LABEL: 'Company DDC ID',
      COMPANY_DDC_ID_HINT: 'The ID your company uses to identify this DDC internally',
      COMPANY_DDC_ID_PLACEHOLDER: 'Enter Company DDC ID',
      STEP_LABEL: 'General information',
      STEP_DETAIL: 'Enter name and Government ID',
      LEGAL_NAME_LABEL: 'Legal name',
      LEGAL_NAME_PLACEHOLDER: 'Enter Legal name',
      TRADE_NAME_LABEL: 'Trade name',
      TRADE_NAME_HINT:
        'Or "fictitious name", the name the DDC uses to identify itself to the public',
      TRADE_NAME_PLACEHOLDER: 'Enter Trade name',
      TAX_ID_LABEL: 'Government ID',
      TAX_ID_PLACEHOLDER: 'Enter Government ID',
    },
    ADDRESS_STEP: {
      COUNTRY_LABEL: 'Country',
      STEP_LABEL: 'Address',
      STEP_DETAIL: 'Add location information',
      STATE_LABEL: 'State',
      ZIP_CODE_LABEL: 'Zip code',
      ZIP_CODE_PLACEHOLDER: 'Enter Zip code',
      CITY_LABEL: 'City',
      CITY_PLACEHOLDER: 'Enter City',
      OPERATION_ADDRESS_LABEL: 'Operation address',
      OPERATION_ADDRESS_PLACEHOLDER: 'Enter Operation address',
    },
    COVERAGE_DETAILS_STEP: {
      ALERT_MESSAGE: 'In order to create a new DC, add at least one coverage area.',
      STEP_LABEL: 'Coverage details',
      STEP_DETAIL: 'Set coverage area and delivery range',
      COVERAGE_OPTION_TOOLTIP:
        'Type of coverage is selected when you add a new DDC for the first time.',
      BY_CUSTOMIZED_AREA: 'By customized area',
      COVERAGE_TITLE: 'Coverage area',
      COVERAGE_LABEL: 'Coverage area {number}',
      SET_AREA: 'Set area',
      REMOVE_AREA: 'Remove area',
      DELIVERY_RANGE_LABEL: 'Delivery range',
      DELIVERY_RANGE_DESCRIPTION:
        'Consider the amount of time required from order analysis until delivery. \nMake sure to input the values from minimum to maximum.',
      DELIVERY_RANGE_FROM: 'From',
      DELIVERY_RANGE_TO: 'to',
      DELIVERY_RANGE_CALENDAR_DAYS: 'calendar days until delivery',
      DELIVERY_RANGE_INVALID_MESSAGE: 'The minimum number must be smaller than the maximum one.',
      DELIVERY_DATES_LABEL: 'Delivery days',
      DELIVERY_DATES_DESCRIPTION:
        'Set the week days available for delivery so the customer is able to know when the order placed will arrive more precisely.',
      ADD_ANOTHER_AREA: 'Add another area',
      EDIT_MODAL_TITLE: 'Edit coverage area',
      SET_MODAL_TITLE: 'Set coverage area',
      EDIT_AREA: 'Edit area',
      EDIT_AREA_TITLE:
        "Draw a custom shape with limits to define a delivery coverage area and double-click to close it. Shapes can't overlap",
      DRAW_POLYGON_MESSAGE: 'Create a coverage area by drawing a shape through anchor points',
      AREA_TEXT: 'Area #{number}',
      DELIVERY_FEE_LABEL: 'Delivery Fee',
      DELIVERY_FEE_DESCRIPTION: 'If set, it will be applied to all coverage areas.',
      DELIVERY_FEE_INPUT_LABEL: 'Total delivery fee per order',
      MINIMUM_FOR_FREE_DELIVERY_INPUT_LABEL: 'Minimum total for free delivery',
    },
    NOTIFICATION: {
      CREATION_SUCCESS: 'Distribution center successfully created.',
      EDITION_SUCCESS: 'Distribution center successfully updated.',
      DELETION_SUCCESS: 'Distribution center successfully deleted.',
      DELETION_FAILURE: "DDC couldn't be deleted due to a system error. Try again later.",
      REMOVE_COVERAGE_AREA_SUCCESS: 'Coverage area {index} successfully removed.',
    },
    DETAILS: {
      COVERAGE_AND_SHIPMENT: 'Coverage and shipment',
      EDIT_COVERAGE_AREAS: 'Edit coverage areas',
      EDIT_DISTRIBUTION_CENTER: 'Edit distribution center',
      DELETE_DISTRIBUTION_CENTER: 'Delete distribution center',
      DISTRIBUTION_CENTER_INFORMATION: 'Distribution center information',
      DELIVERY_DATES_TITLE: 'Delivery days',
      COVERAGE_AREA_TITLE: 'Coverage area {number}',
      DELIVERY_RANGE_TITLE: 'Delivery range',
      COVERAGE_TYPE_TITLE: 'Coverage type',
      ADDITIONAL_NAMES_LABEL: `and {itemCount, plural,
        one {1 other area}
        other {{itemCount} other areas}
      }.`,
      CUSTOMIZED_AREA_LABEL: 'Custom area {number}',
      DELIVERY_RANGE_LABEL: 'From {from} to {to} calendar days until delivery.',
      DDC_ID_TITLE: 'DDC ID',
      COMPANY_DDC_ID_TITLE: 'Company DDC ID',
      LEGAL_NAME_TITLE: 'Legal name',
      TRADE_NAME_TITLE: 'Trade name',
      OPERATIONAL_ADDRESS_TITLE: 'Operation address',
      COUNTRY_TITLE: 'Country',
      TAX_ID_TITLE: 'Government ID',
      CITY_TITLE: 'City',
      DELIVERY_FEE_TITLE: 'Total delivery fee per order',
      MINIMUM_FOR_FREE_DELIVERY: 'Minimum total for free delivery',
      DISTRIBUTION_CENTER_COVERAGE_INFORMATION: 'Coverage information',
      ACCOUNT_NUMBER: 'Number of accounts reached',
    },
    EDIT: {
      PAGE_TITLE: 'Edit distribution center',
      HEADER_MESSAGE: 'You are editing this distribution center',
    },
    CANCELLATION_MODAL: {
      CANCEL: 'No, keep editing',
      CONFIRM: 'Yes, cancel',
      TITLE: 'Are you sure you want to cancel?',
      DESCRIPTION: "All the entered information will be lost and this action can't be undone.",
    },
    DELETE_DISTRIBUTION_CENTER_MODAL: {
      TITLE: 'Delete distribution center',
      DESCRIPTION:
        'All the information about "{ddc}" will be deleted. You can\'t undo this action.',
      CONFIRM: 'Yes, delete',
      CANCEL: 'No, cancel',
    },
    DELETE_COVERAGE_AREA_MODAL: {
      TITLE: 'Remove coverage area',
      DESCRIPTION: 'Are you sure you want to remove Coverage Area {index}?',
      CONFIRM: 'Remove',
    },
  },
  LIST_PAGE: {
    TITLE: 'Distribution centers',
    BUTTONDDC: 'Add new DDC',
    TABLE_HEADER_1: 'Distribution center',
    TABLE_HEADER_2: 'City',
    TABLE_HEADER_3: 'State',
    TABLE_HEADER_4: 'Coverage',
    TABLE_HEADER_5: 'Delivery Range',
    TABLE_HEADER_6: 'Company',
    TABLE_HEADER_7: 'Service Model',
    ROWS: 'Rows per page',
    RANGES: 'ranges',
    BUSINESS_DAYS: 'days',
    TO: 'to ',
    AREAS: '{numberOfAreas} areas',
    CUSTOM_AREA: '{numberOfCustomAreas} custom area',
    CUSTOM_AREAS: '{numberOfCustomAreas} custom areas',
  },
  FILTER_BY_COUNTRY: {
    LABEL: 'Filter by country',
  },
  COUNTRIES: {
    AR: 'Argentina',
    BR: 'Brazil',
    CA: 'Canada',
    CO: 'Colombia',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    ES: 'Canary Islands',
    HN: 'Honduras',
    KR: 'South Korea',
    MX: 'Mexico',
    PA: 'Panama',
    PE: 'Peru',
    PY: 'Paraguay',
    SV: 'El Salvador',
    ZA: 'South Africa',
  },
  REGISTRATION_DDC_MODAL: {
    TITLE: 'Add new distribution center',
    TITLE_SUBTITLE: 'You are adding a new DDC to ',
    STEPPER_TITLE_1: 'DDC information',
    STEPPER_TITLE_2: 'Coverage & shipment',
    LABEL_REQUIRED: 'This field is required.',
    LABEL_REQUIRED_MULTI_FIELDS: 'These fields are required.',
    BUTTON_CANCEL: 'CANCEL',
    BUTTON_NEXT: 'NEXT',
    INVALID_GOV_ID: 'Invalid Government ID',
  },
  REGISTRATION_DDC_FIRST_FLOW: {
    SUBTITLE: 'Distribution center information',
    TIP: 'Start registering a new DDC by adding all the required information.',
    LABEL_NAME: 'Legal name',
    LABEL_TRADE: 'Trade name',
    LABEL_TRADE_INFO:
      'Or "fictitious name", is the name the DDC uses to identify itself to the public.',
    IDENTIFIER: 'Government ID',
    IDENTIFIER_INFO: 'The number of characters may vary depending on the country of origin.',
    LABEL_ADDRESS: 'Operation address',
    LABEL_CITY: 'City',
    LABEL_STATE: 'State',
    LABEL_ZIP_CODE: 'Zip Code',
    LABEL_COUNTRY: 'Country',
  },
  REGISTRATION_DDC_SECOND_FLOW: {
    TITLE: 'Coverage & shipment',
    TITLE_INFORMATION:
      'Coverage and shipment details will guarantee your orders are received and prepared at the right time by the distribution center.',
    LABEL_COVERAGE: 'Coverage area',
    LABEL_COVERAGE_INFO:
      "If a state/city were already selected by another DDC it won't appear available.",
    LABEL_STATE: 'State',
    LABEL_CITY: 'Cities',
    LABEL_ABI_DDC: 'Ab-Inbev DDC',
    LABEL_EXPECTED_DAYS: 'Expected delivery days',
    LABEL_EXPECTED_DAYS_INFO:
      'Consider the necessary amount of time required from order analysis until delivery. Make sure to input the values from minimum to maximum.',
    LABEL_FROM: 'From',
    LABEL_TO: 'To',
    LABEL_DELIVERY: 'days until delivery',
    LABEL_ADD_AREA: 'ADD ANOTHER AREA',
    LABEL_BUTTON_BACK: 'BACK',
    LABEL_BUTTON_ADD: 'ADD',
    MIN_CHARACTER: 'The minimum number must be smaller than the maximum one.',
  },
  CONFIRMATION_MODAL: {
    TITLE: 'Are you sure?',
    CONTENT: 'You are about to lose unsaved information. Are you sure you want to close?',
    BUTTON_CANCEL: 'CANCEL',
    BUTTON_CLOSE: 'CLOSE',
  },
  REGISTRATION_FINISH_MODAL: {
    TITLE: 'Before you finish...',
    SUBTITLE:
      "Make sure to review all the information. You won't be able to edit or delete it later.",
    LABEL_BUTTON_BACK: 'GO BACK',
    LABEL_BUTTON_SAVE: 'SAVE',
  },
  REGISTER_DDC: {
    FEEDBACK_TOAST_SUCCESS: 'A new DDC was added',
    LABEL_ERROR_TITLE: 'Something went wrong.',
    LABEL_ERROR_SUBTITLE: "Sorry, we're having some technical issues. Try again later.",
    BUTTON_BACK: 'GO BACK',
    BUTTON_CLOSE: 'CLOSE',
  },
  BREADCRUMB: {
    TITLE_PAGE: 'Distribution centers',
    COMPANY_DETAILS: 'Company details',
  },
  DDC_REGISTRATION_COVERAGE_AREA_STEP: {
    SUBTITLE_EDIT_POLYGON: 'Create a coverage area by drawing a shape through anchor points.',
    DRAW_POLYGON: 'Draw custom shape by polygon',
    EDIT_POINTS: 'Edit points',
    DELETE_AREA: 'Delete area',
  },
  COMPONENTS: {
    NOT_FOUND_HEADER_TEXT: 'Mmm… Looks like this page is gone',
    NOT_FOUND_SUBHEADER_TEXT: "It looks like this page wasn't found or doesn't exist.",
    NOT_FOUND_HOME_BTN: 'Back',
    ERROR_GENERIC: 'Something went wrong',
  },
  SEARCH_AREA: {
    PLACEHOLDER: 'Search by state, city, and others',
    EMPTY_LIST_MESSAGE: 'No results found',
    ERROR: 'An error occurred while searching. Please try again later.',
  },
  ENTITY_TYPE: {
    Country: 'Country name',
    CountrySecondarySubdivision: 'County',
    CountrySubdivision: 'State or Province',
    CountryTertiarySubdivision: 'Named Area',
    Municipality: 'City/Town',
    MunicipalitySubdivision: 'Sub/Super City',
    Neighbourhood: 'Neighbourhood',
    PostalCodeArea: 'Postal Code/Zip Code',
  },
};

export default enUS;
