import React, { useEffect, useRef, useState } from 'react';
import { MoreHorizontal } from '@hexa-ui/icons';
import {
  Container,
  DropdownContainer,
  DropdownItem,
  DropdownText,
  OptionsButton
} from './DropdownMenu.style';

export interface DropdownMenuProps {
  options: {
    icon: () => JSX.Element;
    label: string;
    onClick: () => void;
  }[];
}

export function DropdownMenu({ options }: DropdownMenuProps): JSX.Element | null {
  const [showOptions, setShowOptions] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (!containerRef?.current?.contains((event?.target as unknown) as Node)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleClickOnItem = (onClick: () => void) => () => {
    setShowOptions(false);
    onClick();
  };

  return (
    <Container ref={containerRef}>
      <OptionsButton
        icon={() => <MoreHorizontal />}
        type="button"
        data-testid="dropdown-button"
        variant="inherit"
        size="small"
        onMouseDown={handleShowOptions}
      />
      {showOptions && (
        <DropdownContainer data-testid="dropdown-options-list" border="small" elevated="medium">
          {options.map(option => (
            <DropdownItem
              data-testid="dropdown-option"
              key={option.label}
              onClick={handleClickOnItem(option.onClick)}
            >
              {option.icon()}
              <DropdownText size="small">{option.label}</DropdownText>
            </DropdownItem>
          ))}
        </DropdownContainer>
      )}
    </Container>
  );
}
